<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>{{ $store.state.table.persetujuan }}</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-input
                  ><CIcon name="cil-plus" />
                  {{ $store.state.table.tambahModal }}</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>
            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="$store.state.table.perHalaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  :label="$store.state.table.cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model.lazy="filter"
                      type="search"
                      :placeholder="$store.state.table.cariHolder"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >{{ $store.state.table.hapusCari }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template>
                  <template #cell(tanggalDibuatSurat)="item">
                    {{ moment(item.item.tanggalDibuatSurat).format("LL") }}
                  </template>
                  <!-- <template #cell(jatuhTempoKontrak)="item">
                    {{ moment(item.item.jatuhTempoKontrak).format("LL") }}
                  </template> -->
                  <template #cell(actions)="item">
                    <!-- <b-button
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      v-b-modal.modal-edit
                      @click="(data = item.item), (edit = !edit)"
                      ><CIcon name="cil-pencil" />{{ item.actions }}</b-button
                    > -->

                    <b-button
                      variant="info"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Detail'"
                      v-b-modal.modal-detail
                      @click="(data = item.item), setEdit()"
                      ><CIcon name="cil-info" />{{ item.actions }}</b-button
                    >
                    <b-button
                      variant="success"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Upload'"
                      v-b-modal.modal-upload
                      @click="(data = item.item), setEdit(), (load = !load)"
                      ><CIcon name="cil-paperclip" />{{
                        item.actions
                      }}</b-button
                    >

                    <b-button
                      variant="secondary"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Print Data'"
                      v-b-modal.modal-print
                      @click="prints(item.item)"
                      ><CIcon name="cil-print" />{{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-row>
                  <b-col> Total Data {{ totalRows }}</b-col></b-row>
              </b-col>
              <b-col md="5" offset-md="4">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <ModalInput
      :karyawan="karyawan"
      @alert="triggerAlert($event)"
      :mitra="mitra"
    />

    <ModalEdit
      :data="data"
      :edit="edit"
      :karyawan="karyawan"
      :mitra="mitra"
      @alert="triggerAlert($event)"
    />
    <ModalDetail :data="data" :detail="detail" />
    <ModalUpload
      :data="data"
      :detail="detail"
      :load="load"
      @alert="triggerAlert($event)"
    />
    <ModalPrint :printData="printData" />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import ModalInput from "@/component/hcms/karyawan/persetujuan/modalInput.vue";
import ModalEdit from "@/component/hcms/karyawan/persetujuan/modalEdit.vue";
import ModalUpload from "@/component/hcms/karyawan/persetujuan/modalUpload.vue";
import ModalPrint from "@/component/hcms/karyawan/persetujuan/modalPrint.vue";
import moment from "moment";
moment.locale("id");
import ModalDetail from "@/component/hcms/karyawan/persetujuan/modalDetail.vue";
export default {
  components: {
    ModalEdit,
    ModalDetail,
    ModalInput,
    ModalUpload,
    ModalPrint,
  },
  data() {
    return {
      moment,
      showing: false,
      variant: "success",
      asuransi: [],
      mitra: [],
      dataAsuransi: "",
      msg: "",
      data: "",
      printData: null,
      edit: false,
      detail: false,
      karyawan: [],
      load: false,
      items: [
        {
          no: "-",
          nama: "-",
          alamat: "-",
          email: "-",
          divisi: "-",
          posisi: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Nama Karyawan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nomorSurat",
          label: "Nomor Surat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tanggalDibuatSurat",
          label: "Tanggal Surat Persetujuan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaMitra",
          label: "Nama Mitra",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-5 text-center",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setEdit() {
      let vm = this;
      vm.detail = !vm.detail;
      vm.edit = !vm.edit;
      // FOREACH diganti FOR
      // vm.karyawan.forEach((ele) => {
      //   if (vm.data.dataKaryawanId == ele.dataKaryawanId) {
      //     vm.data.dataKaryawanIdEdit = ele;
      //   }
      //   vm.data.tanggalKontrakEdit = moment(vm.data.tanggalKontrak).format(
      //     "DD/MM/YYYY"
      //   );
      // });
      for (let i = 0; i < vm.karyawan.length; i++) {
        if (vm.data.dataKaryawanId == vm.karyawan[i].dataKaryawanId) {
          vm.data.dataKaryawanIdEdit = vm.karyawan[i];
        }
        vm.data.tanggalDibuatSuratEdit = moment(
          vm.data.tanggalDibuatSurat
        ).format("DD/MM/YYYY");
      }
    },
    prints(x){
      console.log(x)
      x.tanggal = moment(x.tanggalDibuatSurat).format('lll')
      this.printData = x
    },
    async getDatas() {
      let vm = this;
      vm.tableBusy = true;
      let listPersetujuan = await axios.get(
        ipBackend + "suratPersetujuan/list"
      );
      // console.log(listPersetujuan.data.data, "iki");
      vm.items = listPersetujuan.data.data;
      vm.totalRows = vm.items.length;
      let listMitra = await axios.get(ipBackend + "mitra/list");
      vm.mitra = listMitra.data.data;
      let listKaryawan = await axios.get(ipBackend + "dataKaryawan/list");
      vm.karyawan = listKaryawan.data.data.map((item) => {
        item.dataKaryawan =
          item.namaKaryawan + " - " + item.namaDivisi + " - " + item.namaPosisi;
        return item;
      });
      vm.tableBusy = false;
    },
    triggerAlert(event) {
      let vm = this;
      vm.showing = event.showing;
      vm.variant = event.variant;
      vm.msg = event.msg;
      vm.getDatas();
      setTimeout(() => {
        vm.showing = false;
      }, 4000);
    },
    print(data) {
      // console.log(data);
    },
  },
};
</script>
