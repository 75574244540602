<template>
  <div>
    <b-modal
      id="modal-input"
      size="lg"
      centered
      title="Tambah Data Persetujuan Karyawan"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            No. Surat <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            v-model="$v.data.nomorSurat.$model"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Data Karyawan <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="$v.data.dataKaryawanId.$model"
            :options="karyawan"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            track-by="dataKaryawanId"
            label="dataKaryawan"
            placeholder="-- Pilih Karyawan --"
          ></multiselect>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal Surat Persetujuan <span class="text-danger">*</span>
          </template>
          <date-picker
            v-model="$v.data.tanggalDibuatSurat.$model"
            style="width: 100%"
          ></date-picker>
        </b-form-group>
        <b-form-group label="Nama Mitra" label-cols-md="3">
          <multiselect
            v-model="$v.data.mitraId.$model"
            :state="checkIfValid('mitraId')"
            :options="mitra"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaMitra"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <!-- <b-form-group label-cols-md="3">
          <template v-slot:label>
            Detail Persetujuan <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            v-model="data.detail"
            style="width: 100%"
          ></b-form-textarea>
        </b-form-group> -->
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          @click="simpan()"
          :disabled="!isValid || busy"
        >
          Simpan
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  components: { Multiselect, DatePicker },
  props: ["karyawan", "mitra"],
  name: "modalInput",
  data() {
    return {
      data: {
        dataKaryawanId: "",
        tanggalDibuatSurat: "",
        detail: "",
        mitraId: "",
        nomorSurat: "",
      },
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      dataKaryawanId: {
        required,
      },
      tanggalDibuatSurat: {
        required,
      },
      mitraId: {
        required,
      },
      nomorSurat: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.data.dataKaryawanId = vm.data.dataKaryawanId.dataKaryawanId;
      vm.data.mitraId = vm.data.mitraId.id;
      axios
        .post(ipBackend + "suratPersetujuan/register", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.data.dataKaryawanId = "";
            vm.data.nomorSurat = "";
            vm.data.tanggalDibuatSurat = "";
            vm.$v.$reset();
            vm.busy = false;
            vm.$emit("alert", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN KONTRAK KARYAWAN",
              showing: true,
            });
            this.$bvModal.hide("modal-input");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
