<template>
  <div>
    <b-modal
      id="modal-upload"
      size="lg"
      centered
      scrollable
      title="Upload Surat Persetujuan"
      header-bg-variant="success"
      header-text-variant="light"
    >
      <div
        style="
          text-align: center;
          padding: 2%;
          font-weight: 600;
          font-size: 21px;
        "
      >
        {{ data.nomorSurat }}
      </div>
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Upload Surat Persetujuan<span class="text-danger">*</span>
          </template>
          <b-form-file
            id="file1"
            ref="file1"
            @input="handleFile()"
          ></b-form-file>
        </b-form-group>
      </b-form>
      <!-- <center>
         <div v-if="isPdf">
          {{ currentPage }} / {{ pageCount }}
          <pdf
            :src="ipBackend + 'asset/file/ + '"
            @num-pages="pageCount = $event"
            @page-loaded="currentPage = $event"
          ></pdf>
        </div>
        <img  v-else :src="src1" alt="" class="imgss" />
      </center> -->
      <center v-if="data.fotoSurat != null">
        <b-button @click="download(data)">Download</b-button>
      </center>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-detail')">
          Batal
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import moment from "moment";
import "moment/locale/id";
import pdf from "vue-pdf";
export default {
  name: "modalUpload",
  props: ["data", "detail"],
  component: {
    pdf,
  },
  data() {
    return {
      moment,
      src1: "",
      src2: "",
      file1: "",
      currentPage: 0,
      pageCount: 0,
      isPdf: false,
    };
  },
  methods: {
    handleFile() {
      this.file1 = this.$refs.file1.files[0];
      this.src1 = URL.createObjectURL(this.file1);
      this.upload();
    },
    async upload() {
      let vm = this;
      let formData = new FormData();
      formData.append("id", vm.data.suratPersetujuanId);
      formData.append("file1", vm.file1);
      let upload = await axios.post(
        ipBackend + "suratPersetujuan/updateFotoSurat",
        formData
      );
      if (upload.data.status == 200) {
        if (upload.data.message == "sukses") {
          vm.$emit("alert", {
            variant: "success",
            msg: "BERHASIL UPLOAD SURAT PERJANJIAN KERJA",
            showing: true,
          });
          this.$bvModal.hide("modal-upload");
        } else {
          vm.$emit("alert", {
            variant: "danger",
            msg: upload.data.message,
            showing: true,
          });
          this.$bvModal.hide("modal-upload");
        }
      } else {
        vm.$emit("alert", {
          variant: "danger",
          msg: "TERJADI KESALAHAN PADA SERVER",
          showing: true,
        });
        this.$bvModal.hide("modal-upload");
      }
    },
    getType() {
      var parts = filename.split(".");
      return parts[parts.length - 1];
    },
    download(data) {
      window.open(ipBackend + "asset/file/" + data.fotoSurat, "_blank");
    },
  },
  watch: {
    load: function (newVal, oldVal) {
      if (oldVal != newVal) {
        this.src1 = "";
        if (this.data.fotoSurat != null) {
          let ext = this.getType(this.data.fotoSurat);
          console.log(ext);
          if (ext.toLowerCase() == "pdf") {
            this.isPdf = true;
          } else {
            this.isPdf = false;
          }
          this.src1 = `${ipBackend}asset/file/${this.data.fotoSurat}`;
        }
      }
    },
  },
};
</script>
<style scoped>
.imgss {
  width: 100%;
  height: auto;
}
</style>
