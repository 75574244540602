<template>
  <div>
    <b-modal
      id="modal-edit"
      size="lg"
      centered
      title="Edit Data Persetujuan Karyawan"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <div
        style="
          text-align: center;
          padding: 2%;
          font-weight: 600;
          font-size: 21px;
        "
      >
        {{ data.nomorSurat }}
      </div>
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Data Karyawan <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="dataEdit.dataKaryawanIdEdit"
            :options="karyawan"
            :disabled="true"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            track-by="dataKaryawanId"
            label="dataKaryawan"
            placeholder="-- Pilih Karyawan --"
          ></multiselect>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal Surat Dibuat <span class="text-danger">*</span>
          </template>
          <date-picker
            v-model="dataEdit.tanggalDibuatSurat"
            style="width: 100%"
            :placeholder="dataEdit.tanggalDibuatSuratEdit"
          ></date-picker>
        </b-form-group>

        <b-form-group label="Nama Mitra" label-cols-md="3">
          <multiselect
            v-model="dataEdit.mitraIdEdit"
            :options="mitra"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaMitra"
            track-by="id"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <!-- <b-form-group label-cols-md="3">
          <template v-slot:label>
            Durasi Kontrak (bulan) <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="number"
            v-model="$v.data.masaBerlakuKontrak.$model"
            style="width: 100%"
          ></b-form-input>
        </b-form-group> -->

        <!-- <b-form-group label-cols-md="3">
          <template v-slot:label>
            Detail Persetujuan <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            v-model="data.detail"
            style="width: 100%"
          ></b-form-textarea>
        </b-form-group> -->
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          @click="simpan()"
          :disabled="!isValid || busy"
        >
          Simpan
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  components: { Multiselect, DatePicker },
  props: ["karyawan", "data", "mitra", "edit"],
  name: "modalInput",
  data() {
    return {
      dataEdit: {
        mitraIdEdit: "",
        dataKaryawanIdEdit: "",
        tanggalDibuatSurat: "",
      },
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataEdit, null, 4);
    },
    isValid() {
      return !this.$v.dataEdit.$invalid;
    },
    isDirty() {
      return this.$v.dataEdit.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataEdit: {
      dataKaryawanIdEdit: {
        required,
      },
      tanggalDibuatSurat: {
        required,
      },
      mitraIdEdit: {
        required,
      },
    },
  },
  watch: {
    edit: function (newVal, oldVal) {
      let vm = this;
      console.log(vm.data, "ini data");
      if (oldVal != newVal) {
        for (let i = 0; i < this.mitra.length; i++) {
          if (this.mitra[i].id == this.data.mitraId) {
            this.dataEdit.mitraIdEdit = this.mitra[i];
          }
        }
        for (let i = 0; i < vm.karyawan.length; i++) {
          if (vm.data.dataKaryawanId == vm.karyawan[i].dataKaryawanId) {
            vm.dataEdit.dataKaryawanIdEdit = vm.karyawan[i];
          }
          vm.dataEdit.tanggalDibuatSuratEdit = moment(
            vm.data.tanggalDibuatSurat
          ).format("DD/MM/YYYY");
        }
      }
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataEdit[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {
      console.log(x, "ini x");
    },
    simpan() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.data.id = vm.data.suratPersetujuanId;
      vm.data.mitraId = vm.dataEdit.mitraIdEdit.id;
      vm.data.tanggalDibuatSurat = vm.dataEdit.tanggalDibuatSurat;
      axios
        .post(ipBackend + "suratPersetujuan/update", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "success",
              msg: "BERHASIL MENGUBAH KONTRAK KARYAWAN",
              showing: true,
            });
            this.$bvModal.hide("modal-edit");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
