<template>
  <div>
    <b-modal
      id="modal-detail"
      size="lg"
      centered
      scrollable
      title="Detail Persetujuan Karyawan"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <div
        style="
          text-align: center;
          padding: 2%;
          font-weight: 600;
          font-size: 21px;
        "
      >
        {{ data.nomorSurat }}
      </div>
      <b-row v-if="data.dataKaryawanIdEdit">
        <b-col cols="12" md="12" lg="12">
          <b-table-simple borderless small>
            <b-tbody>
              <b-tr>
                <b-td style="width: 35%">Nama</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.namaKaryawan }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Posisi</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.dataKaryawanIdEdit.namaPosisi }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Divisi</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.dataKaryawanIdEdit.namaDivisi }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Mitra</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.namaMitra }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Tanggal Persetujuan</b-td>
                <b-td>:</b-td>
                <b-td>{{ moment(data.tanggalDibuatSurat).format("LL") }}</b-td>
              </b-tr>

              <!-- <b-tr>
                    <b-td>Lama Kontrak</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ data.masaBerlakuKontrak }} Bulan</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>Tanggal Berakhir Kontrak</b-td>
                    <b-td>:</b-td>
                    <b-td>{{ moment(data.jatuhTempoKontrak).format('LL') }}</b-td>
                  </b-tr> -->
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-detail')">
          Batal
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ipBackend } from "@/ipBackend";
import moment from "moment";
import "moment/locale/id";
export default {
  name: "modalDetail",
  props: ["data", "detail"],
  data() {
    return {
      moment,
      src1: "",
      src2: "",
    };
  },
  watch: {
    detail: function (newVal, oldVal) {
      if (oldVal != newVal) {
        console.log(this.data.dataKaryawanIdEdit, "tes");
        // if (this.data.dataKaryawanIdEdit.statusKerjaKaryawan == 0) {
        //   this.data.dataKaryawanIdEdit.statusKerja = "Kontrak";
        // } else if (this.data.dataKaryawanIdEdit.statusKerjaKaryawan == 1) {
        //   this.data.dataKaryawanIdEdit.statusKerja = "Permanen";
        // } else if (this.data.dataKaryawanIdEdit.statusKerjaKaryawan == 2) {
        //   this.data.dataKaryawanIdEdit.statusKerja = "Resign / Diberhentikan";
        // }
        this.src1 = "";
        this.src2 = "";
        if (this.data.fotoProfilKaryawan != null) {
          this.src1 = `${ipBackend}asset/file/${this.data.fotoProfilKaryawan}`;
        }
        if (this.data.fotoKKKaryawan != null) {
          this.src2 = `${ipBackend}asset/file/${this.data.fotoKKKaryawan}`;
        }
      }
      // console.log(this.data);
    },
  },
};
</script>
